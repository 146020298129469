import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

import componentStyles from "./career.module.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const Career: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )

  const careerClasses = classNames(componentStyles.career, "container")
  const careerTitleClasses = classNames(
    componentStyles.careerTitle,
    "large-section-title"
  )

  const contactTitleClasses = classNames(
    componentStyles.contactTitle,
    "large-section-title"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "career.seo.description" })}
        title={intl.formatMessage({ id: "career.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({ id: "career.banner.title" })}
            </h1>
            <p className={componentStyles.bannerText}>
              {intl.formatMessage({ id: "career.banner.text" })}
            </p>
          </div>
        </div>
      </section>
      <section className={careerClasses}>
        <h2 className={careerTitleClasses}>
          {intl.formatMessage({ id: "career.advantages.title" })}
        </h2>
        <h3 className={componentStyles.subtitle}>
          {intl.formatMessage({ id: "career.advantages.list.first.title" })}
        </h3>
        <p>{intl.formatMessage({ id: "career.advantages.list.first.text" })}</p>
        <h3 className={componentStyles.subtitle}>
          {intl.formatMessage({ id: "career.advantages.list.second.title" })}
        </h3>
        <p>
          {intl.formatMessage({ id: "career.advantages.list.second.text" })}
        </p>
        <h3 className={componentStyles.subtitle}>
          {intl.formatMessage({ id: "career.advantages.list.third.title" })}
        </h3>
        <p>{intl.formatMessage({ id: "career.advantages.list.third.text" })}</p>
        <h3 className={componentStyles.subtitle}>
          {intl.formatMessage({ id: "career.advantages.list.fourth.title" })}
        </h3>
        <p>
          {intl.formatMessage({ id: "career.advantages.list.fourth.text" })}
        </p>
        <h2 className={contactTitleClasses}>
          {intl.formatMessage({ id: "career.contact.title" })}
        </h2>
        <p className={componentStyles.contact}>
          {intl.formatMessage({ id: "career.contact.prefix" })}
          <a
            href={`mailto:${intl.formatMessage({
              id: "career.contact.email",
            })}`}
          >
            {intl.formatMessage({ id: "career.contact.email" })}
          </a>
          {intl.formatMessage({ id: "career.contact.postfix" })}
        </p>
      </section>
    </Layout>
  )
}

export default Career
